<template>
  <div class="gamemodal mobile">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="../../../assets/img/core/Slot_list_header.png" />
    </div>
    <div class=" modal-body">
      <div class="slot_game right_cont">
        <div class="tit">SLOT</div>
        <div class="slot_family game-list-wrap">
          <ul>
            <template v-if="vendorList && vendorList.length !== 0">
              <template v-for="item in vendorList" :key="item.procId">
                <li v-if="item.groupCode === 'slot'" @click="getSlotList(item.procId)" class="familyItem" :class="{ on: gameVendor === item.procId }">
                  <div class="img">
                    <img class="origin" :src="loadLogoImg(item.procId)" alt="" />
                    <img class="hover" :src="loadLogoImg(item.procId + '-1')" alt="" />
                  </div>
                  <div class="tit">{{ $t(`front.gameCode.slot.${item.procId}`) }}</div>
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="slot-game-list-modal" v-if="activeGameList">
      <div class="slot_list">
        <div class="slot-game-list-header">
          <button type="button" @click="gameListModalClose">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <ul>
          <template v-for="item in gameList" :key="item.symbol">
            <li @click="getGameUrl(item)">
              <div class="img">
                <img :src="item.imgSrc" :alt="item.symbol" />
              </div>
              <div class="tit">
                <span> {{ item.gameName }} </span>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getGameList, getGameUrlSlot } from '@/api/game'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'Slot',
  components: {},
  data() {
    return {
      gameList: [],
      gameVendor: '',
      slotList: [],
      activeGameList: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'productList'
    ]),
    vendorList() {
      return this.productList.filter(item => item.groupCode === 'slot')
    }
  },
  methods: {
    closeGameWelcome(e) {
      store.dispatch('storeGameComponent', '')
    },
    async listParser(gameList) {
      this.gameList = []
      // console.log(gameList)
      const gameListObject = gameList
      const lang = this.$i18n.locale === 'ko' ? 'name_KOR' : 'name_ENG'

      for (const item of gameListObject) {
        const slotObject = {
          symbol: item.code,
          gameName: item[lang],
          imgSrc: item.thumbnail
        }
        this.gameList.push(slotObject)
      }
    },
    gameListModalOpen() {
      this.activeGameList = true
    },
    gameListModalClose() {
      this.activeGameList = false
    },
    async getSlotList(vendorKey) {
      this.gameVendor = vendorKey
      const params = {
        vendorKey: vendorKey,
        gameType: 'slot',
        siteId: this.userData.siteId
      }
      try {
        const response = await getGameList(params)
        // const response = slotList
        // console.log(response)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          const { gameList } = data
          await this.listParser(gameList)
          this.gameVendor = vendorKey
          this.gameListModalOpen()
        } else {
          alert('게임 목록을 불러오는데 실패했습니다.')
          console.log(typeof resultCode, resultMessage)
          throw new Error('get pp game list fail')
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getGameUrl(item) {
      const gameWindow = window.open('/preload', '_blank', 'width=1280,height=720')
      this.emitter.emit('Loading', true)
      const params = {
        vendorKey: this.gameVendor,
        gameKey: item.symbol,
        isMobile: isMobile() ? '1' : '0',
        lang: this.$i18n.locale
      }
      try {
        // evolution test용
        let response = null
        response = await getGameUrlSlot(params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          gameWindow.location.href = data.gameUrl
          this.emitter.emit('Loading', false)
        } else {
          gameWindow.close()
          throw new Error(this.$t(`api.${resultCode}`))
        }
      } catch (e) {
        alert(e.message)
        console.log(e)
        // console.log(this.$router)
      }
      this.emitter.emit('Loading', false)
    },
    loadLogoImg(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/logo/${procId}.png`)
      } catch (e) {
        // console.log(e)
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/game.scss';

.mobile .family-item {
  width: 45% !important;
}

.slot_list li {
  min-width: unset;
}

.slot-game-list-modal .slot_list .tit {
  word-break: keep-all;
}
</style>
