<template>
  <div class="left_section" :class="{ active: $props.isActive }">
    <div class="left_menu bg_1">
      <ul>
        <li>
          <a @click="openGameWelcome('Casino')">
            <i ><img src="@/assets/img/winner_pc/icon/casino_active.png?v=1" alt="" /></i>
            <span>{{ $t('front.gameCategory.casino') }}</span>
          </a>
        </li>
        <li>
          <a @click="openGameWelcome('Slot')">
            <i><img src="@/assets/img/winner_pc/icon/slot_active.png?v=1" alt="" /></i><span>{{ $t('front.gameCategory.slot') }}</span></a>
        </li>
        <li>
          <a @click="openGameWelcome('Sports')">
            <i><img src="@/assets/img/winner_pc/icon/footBall_active.png" alt="" /></i>
            <span>{{ $t('front.gameCategory.sports') }}</span></a>
        </li>
        <li>
          <a @click="openGameWelcome('Mini')">
            <i><img src="@/assets/img/winner_pc/icon/minigame_active.png?v=1" alt="" /></i><span>{{ $t('front.gameCategory.minigame') }}</span></a>
        </li>
      </ul>
    </div>
    <div class="left_menu bg_2">
      <ul>
        <li>
          <a @click="goPageByName('betList')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/history_active.png" alt="" /></i><span>{{ $t('front.common.betHistory') }}</span>
          </a>
        </li>
        <li>
          <routerLink to="/sports/history">
            <i class='no_hover'><img src="@/assets/img/winner_pc/icon/matchResult_active.png" alt="" /></i><span>{{ $t('front.common.gameResult') }}</span>
          </routerLink>
        </li>
        <li>
          <a @click="goPageByName('qnaList')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/edit_active.png?v=1" alt="" /></i>
            <span>{{ $t('front.common.qna') }}</span>
          </a>
        </li>
        <li>
          <a @click="goPageByName('msg')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/message_active.png?v=1" alt="" /></i><span>{{ $t('front.common.notes') }}</span>
          </a>
        </li>
        <template v-if="siteOption">
          <li v-if="siteOption.boardYn === 'N'">
            <a @click="goPageByName('noticeList')">
              <i class='hover'><img src="@/assets/img/winner_pc/icon/notification_active.png?v=1" alt="" /></i><span>{{ $t('front.common.notice') }}</span>
            </a>
          </li>
          <li v-else>
            <a @click="goPageByName('boardList')">
              <i class='hover'><img src="@/assets/img/winner_pc/icon/notification_active.png?v=1" alt="" /></i><span>{{ $t('front.common.notice') }}</span>
            </a>
          </li>
        </template>
        <li v-if="mainInfo.eventList.length!==0">
          <a @click="goPageByName('eventList')">
            <i><img src="@/assets/img/winner_pc/icon/event_active.png" alt="" /></i><span>{{ $t('front.common.event') }}</span>
          </a>
        </li>
        <li>
          <a @click="goPageByName('point')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/point_active.png?v=1" alt="" /></i><span>{{ $t('front.common.point') }}</span>
          </a>
        </li>
        <li>
          <a @click="goPageByName('info')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/mypage_active.png?v=1" alt="" /></i><span>{{ $t('front.common.mypage') }}</span>
          </a>
        </li>
        <li>
          <a @click="goPageByName('charge')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/deposit_active.png?v=1" alt="" /></i><span>{{ $t('front.common.MakeAdeposit') }}</span>
          </a>
        </li>
        <li>
          <a @click="goPageByName('exchange')">
            <i class='hover'><img src="@/assets/img/winner_pc/icon/withdraw_active.png?v=1" alt="" /></i><span>{{ $t('front.common.makeAwithdraw') }}</span>
          </a>
        </li>
        <template v-if="siteOption">
          <template v-if="userData">
            <li v-if="siteOption.recommendYn === 'Y' && userData.recommendYn === 'Y'">
            <a @click="goPageByName('partner')">
              <i><img src="@/assets/img/winner_pc/icon/partner_active.png" alt="" /></i> {{ $t('front.mypage.exclusive') }}
            </a>
          </li>
          </template>
          <li v-if="siteOption.sns1">
            <a :href="siteOption.sns1Link ? siteOption.sns1Link : '#'" class="sns-link" :class="`sns-border-${siteOption.sns1}`">
              <img :src="loadSnsIcon(siteOption.sns1)" :alt="`${siteOption.sns1}: ${siteOption.sns1Id}`">
              <span>
                <template v-if="siteOption.sns1Id && userData">
                  {{ siteOption.sns1Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns1}`) }}
                </template>
              </span>
            </a>
          </li>
          <li v-if="siteOption.sns2">
            <a :href="siteOption.sns2Link ? siteOption.sns2Link : '#'" class="sns-link" :class="`sns-border-${siteOption.sns2}`">
              <img :src="loadSnsIcon(siteOption.sns2)" :alt="`${siteOption.sns2}: ${siteOption.sns2Id}`">
              <span>
                <template v-if="siteOption.sns2Id && userData">
                  {{ siteOption.sns2Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns2}`) }}
                </template>
              </span>
            </a>
          </li>
          <li v-if="siteOption.sns3">
            <a :href="siteOption.sns3Link ? siteOption.sns3Link : '#'" class="sns-link" :class="`sns-border-${siteOption.sns3}`">
              <img :src="loadSnsIcon(siteOption.sns3)" :alt="`${siteOption.sns3}: ${siteOption.sns3Id}`">
              <span>
                <template v-if="siteOption.sns3Id && userData">
                  {{ siteOption.sns3Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns3}`) }}
                </template>
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'Navigation',
  props: ['isActive'],
  data() {
    return {
      snsList: []
    }
  },
  computed: {
    ...mapState([
      'siteOption',
      'userData',
      'mainInfo'
    ])
  },
  methods: {
    async openGameWelcome(value) {
      this.emitter.emit('Loading', true)
      await store.dispatch('storeGameComponent', value)
      this.emitter.emit('Loading', false)
    },
    loadSnsIcon(name) {
      try {
        return require(`@/assets/img/${name}.svg`)
      } catch (e) {
        console.log(e)
        return ''
      }
    }
  }
}
</script>

<!-- <style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/navigation.css"></style>
<style scoped src="@/styles/header.css"></style> -->
